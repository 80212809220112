import styled from "styled-components";
import { Box } from "../Basic";

export const Wrapper = styled(Box)`
  font-size: 12px;
  font-weight: 500;
  padding: 8px 24px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  border-radius: 100px;
  background-color: ${(props) =>
    props.isSelected ? "var(--colors-purple)" : "var(--colors-purple-20)"};
  color: ${(props) => (props.isSelected ? "#ffffff" : "var(--colors-purple)")};
  cursor: pointer;
  :hover {
    background-color: var(--colors-purple-40);
  }
`;

export const Tag = ({ isSelected, children, ...props }) => {
  return (
    <Wrapper isSelected={isSelected} {...props}>
      {children}
    </Wrapper>
  );
};
