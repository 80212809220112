import { useCollections } from "../../backend/useCollections";
import { Row } from "../../components/Basic";
import { GenderChart } from "./charts/GenderChart";
import { Layout } from "../../components/Layout";
import { useStateContext } from "../../state/StateProvider.jsx";
import { DashboardHeader } from "./DashboardHeader";
import { DashboardGrid } from "./DashboardGrid";
import { AgeChart } from "./charts/AgeChart";
import { DiversityBarChart } from "./charts/DiversityBarChart";
import { Menu, Title } from "../../components/Layout/Menu.jsx";
import { DataFilter } from "../../components/filter/DataFilter";
import { AgeRange } from "./AgeRange";
import { If } from "../../components/If";
import { isDefined } from "@codewell/is-defined";
import { useAggregatedPersons } from "../../backend/useAggregatedPersons.js";
import { useCategories } from "../../backend/useCategories.js";
import { ChartLoader } from "../../components/ChartLoader";
import { NoCollection } from "../../components/NoCollection";
import { FaceRepresentation } from "./charts/FaceRepresentation";
import { labelIdToDisplayName } from "../../utils/labelIdToDisplayName";
import { RankSelect } from "../../components/RankSelect.jsx";

export const Dashboard = () => {
  const { state } = useStateContext();

  const { collections, isLoading: collectionsIsLoading } = useCollections();
  const { isLoading: categoriesIsLoading } = useCategories(collections[0]?.id);
  const { data: chartData, isLoading: aggregatedPersonsIsLoading } =
    useAggregatedPersons(collections[0]?.id);

  const chartsShouldLoad =
    aggregatedPersonsIsLoading ||
    collectionsIsLoading ||
    categoriesIsLoading ||
    !isDefined(chartData);

  return (
    <Layout>
      <DashboardHeader
        data={chartData}
        categoriesIsLoading={categoriesIsLoading}
      />

      <If test={!collectionsIsLoading && collections.length === 0}>
        <NoCollection />
      </If>

      <Row width="100%" alignItems="flex-start" flex-wrap="nowrap">
        <DashboardGrid flex="1">
          <ChartLoader isLoading={chartsShouldLoad} gridArea="A">
            <GenderChart data={chartData} gridArea="A" />
          </ChartLoader>

          <ChartLoader isLoading={chartsShouldLoad} gridArea="B">
            <AgeChart data={chartData} gridArea="B" />
          </ChartLoader>

          <ChartLoader isLoading={chartsShouldLoad} gridArea="C">
            <DiversityBarChart
              title="Ethnic Background"
              data={chartData}
              dataKey="ethnicity"
              colorScheme="blue"
              gridArea="C"
            />
          </ChartLoader>
        </DashboardGrid>

        <ChartLoader isLoading={chartsShouldLoad} width="30%" marginLeft="16px">
          <FaceRepresentation data={chartData} width="30%" marginLeft="16px" />
        </ChartLoader>
      </Row>

      <Menu isOpen={state.isFilterMenuOpen}>
        <Title>Ages</Title>
        <AgeRange />

        <Title>Rank</Title>
        <RankSelect />

        <If test={state?.filters?.options?.gender?.length > 0}>
          <Title>Gender</Title>
          <DataFilter category="gender" renderAs={labelIdToDisplayName} />
        </If>

        <If test={state?.filters?.options?.ethnicity?.length > 0}>
          <Title>Ethnic Background</Title>
          <DataFilter category="ethnicity" renderAs={labelIdToDisplayName} />
        </If>

        <If test={state?.filters?.options?.labels?.length > 0}>
          <Title>Labels</Title>
          <DataFilter category="labels" />
        </If>

        <If test={state?.filters?.options?.mediaType?.length > 0}>
          <Title>Media Type</Title>
          <DataFilter category="mediaType" />
        </If>
      </Menu>
    </Layout>
  );
};
