import { isDefined } from "@nextml/lodestar";
import { cssObjectToString, keepCssProps } from "./cssObjectToString";
import { exclude } from "./objects/exclude";
import { takeIf } from "./objects/take";

const validate = (cssProps) => {
  const propValues = Object.keys(cssProps).map((prop) => cssProps[prop]);

  if (propValues.length === 0) {
    return cssProps;
  }

  if (!isDefined(...propValues)) {
    console.warn(
      "ignoring css props that are not defined in",
      cssProps,
      "->",
      takeIf((_, prop) => isDefined(cssProps[prop]))(cssProps)
    );

    return takeIf((_, prop) => isDefined(cssProps[prop]))(cssProps);
  }

  return cssProps;
};

export const useCssProps = (props) => {
  if (typeof props !== "object") {
    throw new Error("cssProps must be an object");
  }

  return cssObjectToString(validate(exclude("src")(keepCssProps(props))));
};
