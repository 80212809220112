import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Box, Center, VerticalSpacer } from "../Basic";
import { If } from "../If.jsx";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { Rotate } from "../Rotate";
import { BackgroundGradient } from "../BackgroundGradient";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--colors-background);
  min-height: 100vh;
  height: 100vh;
  padding: 32px;
  box-sizing: border-box;
  overflow-y: scroll;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 128px;
  color: #ffffff;
`;

const LoaderWrapper = styled(Center)`
  width: 100%;
  height: 90%;
`;

// Loader for entire view
const Loader = ({ children }) => {
  return (
    <LoaderWrapper>
      <Rotate speed="1s" timingFunction="ease-in-out">
        <Icon icon={faSyncAlt} />
      </Rotate>

      <VerticalSpacer height="32px" />

      <Box color="#ffffff" fontSize="20px">
        {children || "Hold on while we prepare your data..."}
      </Box>
    </LoaderWrapper>
  );
};

export const Layout = ({ children, isLoading, loadingMessage }) => {
  return (
    <Wrapper>
      <If test={!isLoading}>{children}</If>
      <If test={isLoading}>
        <BackgroundGradient
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
        >
          <Loader>{loadingMessage}</Loader>
        </BackgroundGradient>
      </If>
    </Wrapper>
  );
};
