import { chain } from "@codewell/chain";
import { map } from "../../../../utils/arrays/map";
import { sort } from "../../../../utils/arrays/sort";
import { labelIdToDisplayName } from "../../../../utils/labelIdToDisplayName";
import { keys } from "../../../../utils/objects/keys";
import { pick } from "../../../../utils/objects/pick";

export const prepareData = (data, dataKey) =>
  chain(
    data,
    pick(dataKey),
    keys,
    sort,
    map((key) => ({
      name: labelIdToDisplayName(key),
      n: Math.round(data[dataKey][key] * 100),
    }))
  );
