/**
 * This view is for development purposes only
 * All visual components should be listed here to
 * give a catalogue of available components and
 * what they look like
 *
 */

import { Box, Card, Flex } from "../../components/Basic";
import { IconButton } from "../../components/buttons/IconButton";
import { Layout } from "../../components/Layout";
import filterIcon from "../../icons/filter.svg";
import { Tag } from "../../components/Tag/index.jsx";

const COMPONENTS = [
  { Component: Card, props: { children: <div>card</div> } },
  { Component: Box, props: { children: <div>box</div> } },
  { Component: Box, props: { border: "1px solid", children: <div>flex</div> } },
  { Component: IconButton, props: { icon: filterIcon } },
  {
    Component: Flex,
    props: {
      border: "1px solid",
      backgroundColor: "green",
      color: "blue",
      padding: "100px",
      letterSpacing: "10px",
      "font-family": "monospace",
      marginTop: "10px",
      children: <div>box</div>,
    },
  },

  {
    Component: Tag,
    props: { id: "1", children: "tag 1", isSelected: false },
  },
  {
    Component: Tag,
    props: { id: "2", children: "tag 2", isSelected: true },
  },
];

const render = (component, index) => (
  <div style={{ marginBottom: "64px" }} key={`${index}::component`}>
    <p>{component.Component.name}</p>
    <component.Component {...component.props} />
  </div>
);

export const ComponentsView = () => (
  <Layout>
    <h1>Components (Development)</h1>
    {COMPONENTS.map(render)}
  </Layout>
);
