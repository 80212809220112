import styled from "styled-components";
import { Summary } from "./Summary";
import filterIcon from "../../../icons/filter.svg";
import { faImage, faUser, faVideo } from "@fortawesome/free-solid-svg-icons";
import { AlignRight, Grid, Row } from "../../../components/Basic";
import { useStateContext } from "../../../state/StateProvider";
import { IconButton } from "../../../components/buttons/IconButton";
import { defer } from "../../../utils/functional/defer";
import { OPEN_FILTER_MENU } from "../../../state/actionTypes";

const HeaderWrapper = styled(Grid)`
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 16px;
`;

const StatsWrapper = styled(Row)`
  flex: 1;
  grid-column-end: span 2;
`;

const Stats = ({ data }) => {
  return (
    <StatsWrapper>
      <Summary
        colorScheme="purple"
        icon={faVideo}
        text="Videos analyzed"
        amount={data?.n_videos}
      />
      <Summary
        colorScheme="blue"
        icon={faImage}
        text="Images analyzed"
        amount={data?.n_images}
      />
      <Summary
        colorScheme="green"
        icon={faUser}
        text="Number of people"
        amount={data?.n_persons?.toFixed(0)}
      />
    </StatsWrapper>
  );
};

const FilterButtons = ({ categoriesIsLoading }) => {
  const { dispatch } = useStateContext();

  return (
    <AlignRight>
      <Row height="100%">
        <IconButton
          icon={filterIcon}
          onClick={defer(dispatch, { type: OPEN_FILTER_MENU })}
          marginLeft="20px"
          disabled={categoriesIsLoading}
        />
      </Row>
    </AlignRight>
  );
};

export const DashboardHeader = ({ data, categoriesIsLoading }) => (
  <HeaderWrapper>
    <Stats data={data} />
    <FilterButtons categoriesIsLoading={categoriesIsLoading} />
  </HeaderWrapper>
);
