import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ComponentsView } from "./Components/index.jsx";
import { Dashboard } from "./Dashboard";
import { Page404, RequestError, PageError } from "./StatusPage/ErrorPage.jsx";

export const Views = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/development/components" element={<ComponentsView />} />
      <Route path="/error" element={<PageError />} />
      <Route path="/error/:status" element={<RequestError />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  </BrowserRouter>
);
