import { ResponsiveContainer, AreaChart, XAxis, Tooltip, Area } from "recharts";
import { ChartWrapper } from "../ChartWrapper";
import { prepareData } from "./prepareData";

const renderTooltip = (value, _) => <span>{Math.round(10 * value) / 10}</span>;

export const AgeChart = ({ data, ...props }) => (
  <ChartWrapper
    title={"Average age"}
    subtitle={`${data.average_age.toFixed(1)} Years`.replace(".", ",")}
    {...props}
  >
    <ResponsiveContainer height="100%" width="100%">
      <AreaChart data={prepareData(data)} id="ageChart">
        <defs>
          <linearGradient id="ageGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#06C3F8" stopOpacity={0.6} />
            <stop offset="90%" stopColor="#06C3F8" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="name"
          height={15}
          tick={{ fontSize: 12, fontWeight: 600, fontFamily: "Poppins" }}
          axisLine={false}
          stroke="#C9C9CE"
          hide={true}
        />
        <Tooltip cursor={{ fill: "#301b82" }} formatter={renderTooltip} />
        <Area
          type="monotone"
          dataKey="n"
          stroke="#06C3F8"
          strokeWidth={4}
          fillOpacity={1}
          fill="url(#ageGradient)"
          unit="%"
        />
      </AreaChart>
    </ResponsiveContainer>
  </ChartWrapper>
);
