import {
  faArrowRotateBack,
  faHeartBroken,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Column } from "../../components/Basic.jsx";
import { Button } from "../../components/buttons/Button.jsx";
import { isDefined } from "@codewell/is-defined";

const Wrapper = styled(Column)`
  align-items: center;
  width: 100%;
  height: 100vh;
  color: white;
  padding: 64px 0;
  color: var(--colors-purple-dark);
  text-align: center;
`;
const Icon = styled.h1`
  font-size: 64px;
  font-weight: 900;
  margin: 0;
  text-align: center;
`;
const Title = styled.h2`
  font-size: 52px;
  margin: 0;
  margin-bottom: 32px;
`;
const Message = styled.p`
  font-size: 28px;
  max-width: 600px;
  text-align: center;
  word-break: break-word;
`;

const Status = styled.p`
  font-size: 24px;
  max-width: 600px;
  text-align: center;
  word-break: break-word;
  padding: 16px;
  font-weight: 800;
  font-family: monospace;
  background-color: var(--colors-purple-20);
  border-radius: 7px;
  margin-bottom: 0;
`;

export const ErrorPage = ({ title, message, status }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Icon>
        <FontAwesomeIcon icon={faHeartBroken} />
      </Icon>

      <Title>{title}</Title>
      {status ? <Status>{status}</Status> : null}
      {message ? <Message>{message}</Message> : null}
      <Button
        onClick={(_) => {
          navigate("/", { replace: true });
          window.location.reload();
        }}
      >
        <FontAwesomeIcon icon={faArrowRotateBack} /> Go Back
      </Button>
    </Wrapper>
  );
};

export const Page404 = () => (
  <ErrorPage title="404" message="This page does not exist" />
);

export const RequestError = () => {
  const { state } = useLocation();

  if (!isDefined(state?.statusText, state?.status)) {
    return <ErrorPage title="Something went wrong" />;
  }

  return (
    <ErrorPage
      title="Something went wrong"
      status={`${state.status} ${state.statusText}`}
      message={`when requesting ${state.url}`}
    />
  );
};

export const PageError = () => {
  const location = useLocation();

  if (!isDefined(location?.state?.error?.message)) {
    return <ErrorPage title="Something went wrong" message="Unknown error" />;
  }

  return (
    <ErrorPage
      title="Something went wrong"
      status={location.state.error.message}
    />
  );
};
