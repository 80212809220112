import { isDefined } from "@nextml/lodestar";
import { Text } from "./Basic";

export const Map = ({
  data,
  Component,
  keyPrefix,
  isSelected,
  onClick,
  renderAs,
  ...props
}) => {
  if (!isDefined(data) || data.length === 0) {
    return (
      <Text fontSize="14px" fontWeight="700" color="var(--colors-grey-70)">
        No Data Available
      </Text>
    );
  }

  return data?.map((x, index) => {
    return (
      <Component
        key={`${keyPrefix}:${index}`}
        isSelected={isSelected(x)}
        onClick={(event) => onClick(event, x)}
        {...props}
      >
        {renderAs(x)}
      </Component>
    );
  });
};
