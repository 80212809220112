import { isDefined } from "@nextml/lodestar";
import { useEffect } from "react";
import { SET_CATEGORIES } from "../state/actionTypes";
import { useStateContext } from "../state/StateProvider";
import { useAuthorizedFetcher } from "./useAuthorizedFetcher";

export const useCategories = (collectionId) => {
  const authorizedFetcher = useAuthorizedFetcher();
  const { state, dispatch } = useStateContext();

  const url = `/collections/${collectionId}/categories`;

  useEffect(() => {
    if (isDefined(collectionId)) {
      authorizedFetcher(url).then((metadata) => {
        dispatch({ type: SET_CATEGORIES, payload: metadata });
      });
    }
    // eslint-disable-next-line
  }, [collectionId]);

  return {
    isLoading: state.loaders.includes(url),
  };
};
