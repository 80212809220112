import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Card, Column, Flex } from "../../../components/Basic";

const Wrapper = styled(Card)`
  display: flex;
  margin-right: 16px;
  align-items: center;
  min-width: 256px;
  flex: 1;
  height: 100%;
  padding: 12px;

  :last-child {
    margin-right: 0;
  }
`;

const IconWrapper = styled(Flex)`
  background-color: ${(props) => `var(--colors-${props.colorScheme}-20)`};
  border-radius: 100px;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
`;

const Icon = ({ colorScheme, icon }) => (
  <IconWrapper colorScheme={colorScheme}>
    <FontAwesomeIcon
      style={{ color: `var(--colors-${colorScheme})` }}
      icon={icon}
    />
  </IconWrapper>
);

const Title = styled.span`
  font-weight: 600;
  font-size: 22px;
  color: var(--colors-grey-dark);
  margin-left: 16px;
`;
const Description = styled.span`
  font-size: 12px;
  font-family: Poppins;
  color: var(--colors-grey-dark);
  margin-left: 16px;
`;

export const Summary = ({ icon, colorScheme, text, amount }) => (
  <Wrapper>
    <Icon colorScheme={colorScheme} icon={icon} />
    <Column>
      <Title>{amount}</Title>
      <Description>{text}</Description>
    </Column>
  </Wrapper>
);
