import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";
import { ChartWrapper } from "../ChartWrapper";
import { prepareData } from "./prepareData";

const renderTooltip = (value, _) => <span>{Math.round(10 * value) / 10}</span>;

const colorMap = {
  orange: "#F9BC77",
  green: "#63CE99",
  blue: "#04C0F7",
  purple: "",
};

export const DiversityBarChart = ({
  title,
  data,
  dataKey,
  colorScheme,
  ...props
}) => (
  <ChartWrapper title={title} chartMargin="16px 0 0 0" {...props}>
    <ResponsiveContainer height="100%" width="100%">
      <BarChart data={prepareData(data, dataKey)}>
        <CartesianGrid
          strokeDasharray="0 0 "
          stroke="#C9C9CE50"
          vertical={false}
        />
        <XAxis
          dataKey="name"
          height={20}
          dy={7}
          tick={{ fontSize: 12, fontFamily: "Poppins", fontWeight: 500 }}
          axisLine={{ stroke: "#C9C9CE50" }}
          stroke="#474d6c90"
        />
        <YAxis
          width={26}
          tick={{ fontSize: 10 }}
          stroke="#C9C9CE"
          axisLine={false}
          unit="%"
          tickCount={4}
        />
        <Tooltip cursor={{ fill: "#C9C9CE20" }} formatter={renderTooltip} />
        <Bar
          dataKey="n"
          fill={colorMap[colorScheme]}
          unit="%"
          barSize={28}
          radius={[3, 3, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  </ChartWrapper>
);
