import { labelIdToDisplayName } from "../../../../utils/labelIdToDisplayName";

const COLORS = ["#08C3F8", "#5B41F8"];

export const prepareData = (data) =>
  Object.keys(data?.gender).map((gender, index) => ({
    name: labelIdToDisplayName(gender),
    fill: COLORS[index % COLORS.length],
    value: Math.round(data?.gender[gender] * 100),
    total: 100,
  }));
