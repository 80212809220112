import { Box } from "./Basic";
import styled from "styled-components";

export const OverLay = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: #000;
  opacity: 0.5;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  }

  animation: fade-in 0.2s linear;
`;
