import styled from "styled-components";
import { useCssProps } from "../utils/useCssProps";

export const Box = styled.div`
  box-sizing: border-box;
  ${useCssProps};
`;

export const Span = styled.span`
  ${useCssProps};
`;

export const Flex = styled(Box)`
  display: flex;
`;

export const Row = styled(Flex)`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${useCssProps};
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const Card = styled(Box)`
  padding: 16px;
  border-radius: 8px;
  background-color: white;
`;

export const Grid = styled(Box)`
  display: grid;
`;

export const VerticalSpacer = styled(Box)`
  width: 100%;
  ${useCssProps};
`;

export const AlignRight = styled(Row)`
  justify-content: flex-end;
`;

export const Center = styled(Column)`
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  ${useCssProps};
`;

export const Text = styled.span`
  ${useCssProps};
`;
