import { SET_RANK } from "../state/actionTypes";
import { useStateContext } from "../state/StateProvider";
import { Box } from "./Basic";
import styled from "styled-components";
import { isDefined, typeOf } from "@nextml/lodestar";

const Input = styled.input`
  border: none;
  border-radius: 4px;
  font-size: 32px;
  font-weight: 700;
  color: var(--colors-purple);

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:focus,
  &:active {
    outline: none;
  }
`;

const setRank =
  (dispatch) =>
  ({ target: { value } }) =>
    dispatch({ type: SET_RANK, payload: value });

export const RankSelect = () => {
  const { state, dispatch } = useStateContext();

  return (
    <Box>
      <Input
        type="number"
        size={2}
        min={state.filters.options.rank.min}
        max={state.filters.options.rank.max}
        value={state.filters.selected.rank}
        onChange={setRank(dispatch)}
      />
    </Box>
  );
};
