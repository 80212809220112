import { ChartWrapper } from "../ChartWrapper";
import {
  ResponsiveContainer,
  Legend,
  RadialBarChart,
  RadialBar,
  PolarAngleAxis,
} from "recharts";
import styled from "styled-components";
import { prepareData } from "./prepareData.js";

const LegendWrapper = styled.span`
  font-family: var(--fonts-text);
  color: var(--colors-grey-dark);
  margin-left: auto;
`;

const LegendPercentage = styled.span`
  font-weight: 600;
  margin-left: 18px;
`;

const renderLegend = (value, entry) => (
  <LegendWrapper>
    {value}
    <br />
    <LegendPercentage>
      {Math.round(10 * entry.payload.value) / 10}%
    </LegendPercentage>
  </LegendWrapper>
);

export const GenderChart = ({ isLoading, data, ...props }) => (
  <ChartWrapper title="Gender" {...props}>
    <ResponsiveContainer height="100%" width="100%">
      <RadialBarChart
        cx="30%"
        cy="55%"
        innerRadius="65%"
        outerRadius="100%"
        data={prepareData(data)}
        barSize={16}
        startAngle={90}
        endAngle={450}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />

        <RadialBar
          dataKey="value"
          angleAxisId={0}
          data={prepareData(data)}
          cornerRadius={20}
          background
        />

        <Legend
          align="right"
          verticalAlign="middle"
          layout="vertical"
          iconType="circle"
          width="30%"
          formatter={renderLegend}
        />
      </RadialBarChart>
    </ResponsiveContainer>
  </ChartWrapper>
);
