import { Range, createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import { Box } from "../../components/Basic.jsx";
import { SET_AGES } from "../../state/actionTypes.js";
import { useStateContext } from "../../state/StateProvider";
import { first } from "../../utils/arrays/first";
import { last } from "../../utils/arrays/last";

const ToolTipRange = createSliderWithTooltip(Range);

const trackStyle = [{ backgroundColor: "var(--colors-purple-90)" }];
const handleStyle = [
  {
    boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
    width: "18px",
    height: "18px",
    border: "1px solid var(--colors-purple-80)",
    marginTop: "-7px",
  },
  {
    boxShadow: "0 0 3px 0 rgba(0,0,0,0.3)",
    width: "18px",
    height: "18px",
    border: "1px solid var(--colors-purple-80)",
    marginTop: "-7px",
  },
];
const railStyle = { backgroundColor: "var(--colors-purple-20)" };
export const AgeRange = () => {
  const {
    dispatch,
    state: {
      filters: { options, selected },
    },
  } = useStateContext();

  const defaultValue = (
    selected?.age?.from && selected?.age?.to
      ? [selected.age.from, selected.age.to]
      : [options.age.from, options.age.to]
  ).map(Math.round);

  return (
    <>
      <Box width="full" textAlign="right">
        {first(defaultValue)} - {last(defaultValue)}
      </Box>
      <ToolTipRange
        onAfterChange={(ageRange) =>
          dispatch({ type: SET_AGES, payload: ageRange })
        }
        defaultValue={defaultValue}
        step={1}
        min={0}
        max={125}
        trackStyle={trackStyle}
        handleStyle={handleStyle}
        railStyle={railStyle}
      />
    </>
  );
};
