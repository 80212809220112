import styled from "styled-components";
import { Box } from "./Basic";

export const Rotate = styled(Box)`
  @-moz-keyframes rotate {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  animation: rotate ${(props) => props.speed || "1s"}
    ${(props) => props.timingFunction || "linear"}
    ${(props) => props.duration || "infinite"};
`;
