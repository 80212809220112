import { chain } from "@codewell/chain";
import { isDefined } from "@nextml/lodestar";
import { useEffect } from "react";
import {
  SET_AGGREGATED_PERSONS,
  START_LOADER,
  STOP_LOADER,
} from "../state/actionTypes";
import { useStateContext } from "../state/StateProvider";
import { map } from "../utils/arrays/map";
import { useAuthorizedFetcher } from "./useAuthorizedFetcher";

const removeEmptyValuesFrom = (obj) =>
  Object.keys(obj).reduce((result, key) => {
    if (isDefined(obj[key]) && obj[key] !== "") {
      return { ...result, [key]: obj[key] };
    }
    return result;
  }, {});

const constructQueryFrom = (selectedFilters) => {
  if (
    isDefined(
      selectedFilters,
      selectedFilters?.age,
      selectedFilters?.gender,
      selectedFilters?.ethnicity
      // selectedFilters?.date
    )
  ) {
    const params = {
      from_age: selectedFilters?.age?.from,
      to_age: selectedFilters?.age?.to,
      // from_date: selectedFilters?.date?.from?.toISOString(),
      // to_date: selectedFilters?.date?.to?.toISOString(),
      to_face_rank: selectedFilters?.rank,
    };

    return chain(
      params,
      removeEmptyValuesFrom,
      Object.keys,
      map((key) => `${key}=${params[key]}`),
      (queries) => [
        ...queries,
        ...selectedFilters?.gender?.map((gender) => `genders=${gender}`),
        ...selectedFilters?.ethnicity?.map(
          (ethnicity) => `ethnicities=${ethnicity}`
        ),
      ]
    ).join("&");
  }

  return "";
};

export const useAggregatedPersons = (collectionId) => {
  const authorizedFetcher = useAuthorizedFetcher();

  const {
    state: {
      aggregatedPersons,
      filters: { selected: selectedFilters },
      loaders,
    },
    dispatch,
  } = useStateContext();

  const url = `/collections/${collectionId}/persons/aggregate?${constructQueryFrom(
    selectedFilters
  )}`;

  useEffect(() => {
    if (collectionId !== undefined) {
      dispatch({ type: START_LOADER, payload: url });
      authorizedFetcher(url).then((responseData) => {
        dispatch(
          { type: SET_AGGREGATED_PERSONS, payload: responseData },
          { type: STOP_LOADER, payload: url }
        );
      });
    }
    // eslint-disable-next-line
  }, [url]);

  return { data: aggregatedPersons, isLoading: loaders.includes(url) };
};
