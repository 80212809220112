import { Box } from "./Basic";

export const ChartLoader = ({ isLoading, children, ...props }) => {
  if (isLoading) {
    return (
      <Box
        borderRadius="4px"
        className="component-loader"
        height={"100%"}
        maxHeight={"40vh"}
        minHeight="256px"
        {...props}
      />
    );
  }

  return children;
};
