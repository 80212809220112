import styled from "styled-components";
import { Box } from "./Basic";

export const BackgroundGradient = styled(Box)`
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  background: linear-gradient(
    -45deg,
    var(--colors-purple),
    var(--colors-purple-dark)
  );
  background-size: 200% 200%;
  animation: gradient 10s ease infinite;
  height: 100vh;
`;
