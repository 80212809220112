import { useFace } from "../../../../backend/useFace";
import { Box, Image } from "../../../../components/Basic";
import styled from "styled-components";

const Number = styled.span`
  font-family: var(--fonts-text);
  font-weight: 400;
  font-size: 16px;
  color: var(--colors-grey);
`;

export const Face = ({ id, weight, ...props }) => {
  const face = useFace(id);

  return (
    <Box {...props} textAlign="center">
      <Image src={face?.image} borderRadius="100px" />
      <Number>{weight.toFixed(2)}</Number>
    </Box>
  );
};
