export const initialState = {
  collections: [],
  collection: null,
  isFilterOpen: false,

  filters: {
    options: {
      rank: { min: 1, max: 10 },
    },
    selected: {
      rank: 3,
    },
  },

  loaders: ["/collections"],
};
