import { Column, Span } from "../Basic";
import { If } from "../If";
import { OverLay } from "../OverLay";
import styled from "styled-components";
import { useStateContext } from "../../state/StateProvider";
import { defer } from "../../utils/functional/defer";
import { CLOSE_MENU } from "../../state/actionTypes";

const MenuWrapper = styled(Column)`
  position: absolute;
  top: 0;
  right: -500px;
  width: 500px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.6);
  padding: 16px 64px 64px 64px;
  overflow-y: scroll;

  z-index: 2000;
  @keyframes slide-left {
    100% {
      right: 0;
    }
  }
  animation: slide-left 50ms forwards;
`;

export const Title = styled(Span)`
  font-size: 18px;
  margin: 32px 0 8px 0px;
  font-weight: 600;
  color: #474d6c;
`;

export const Label = styled.label`
  font-size: 14px;
  text-transform: uppercase;
  margin: 16px 0 8px 0;
  font-weight: 800;
  color: #474d6c;
`;

export const Menu = ({ children, isOpen }) => {
  const { dispatch } = useStateContext();

  return (
    <If test={isOpen}>
      <OverLay onClick={defer(dispatch, { type: CLOSE_MENU })} />
      <MenuWrapper>{children}</MenuWrapper>
    </If>
  );
};
