import { AuthenticationProvider } from "./auth/Authentication.jsx";
import { ConfigProvider } from "./state/ConfigProvider.js";
import { StateProvider } from "./state/StateProvider.jsx";
import { Views } from "./views/index.jsx";

export const App = () => (
  <ConfigProvider>
    <AuthenticationProvider>
      <StateProvider>
        <Views />
      </StateProvider>
    </AuthenticationProvider>
  </ConfigProvider>
);
