import { useKeycloak } from "@react-keycloak/web";
import { useStateContext } from "../state/StateProvider";
import { useNavigate } from "react-router-dom";
import { useConfig } from "../state/ConfigProvider";

const checkResponseStatus = (response) => {
  if (response.status >= 400) {
    throw response;
  }
  return response;
};

const handleRequestError = (dispatch, navigate) => (error) => {
  if (error.status >= 500 && error.status < 600) {
    const state = {
      url: error.url,
      statusText: error.statusText,
      status: error.status,
    };

    return navigate(`/error/${error.status}`, { state });
  }

  return navigate(`/error`, { state: null });
};

export const useAuthorizedFetcher = () => {
  const { keycloak } = useKeycloak();
  const { dispatch } = useStateContext();
  const navigate = useNavigate();
  const config = useConfig();

  if (config?.api && keycloak?.token) {
    return (endpoint, requestOptions = {}) => {
      return fetch(`${config?.api?.endpoint}${endpoint}`, {
        ...requestOptions,
        method: requestOptions?.method ? requestOptions.method : "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${keycloak.token}`,
          ...requestOptions?.headers,
        },
        body: requestOptions?.body
          ? JSON.stringify(requestOptions.body)
          : undefined,
      })
        .then(checkResponseStatus)
        .then((response) => response.json())
        .catch(handleRequestError(dispatch, navigate));
    };
  } else {
    return null;
  }
};
