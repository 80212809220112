import { useEffect } from "react";
import {
  SET_COLLECTIONS,
  START_LOADER,
  STOP_LOADER,
} from "../state/actionTypes";
import { useStateContext } from "../state/StateProvider";
import { useAuthorizedFetcher } from "./useAuthorizedFetcher";

export const useCollections = () => {
  const authorizedFetcher = useAuthorizedFetcher();

  const {
    state: { collections, loaders },
    dispatch,
  } = useStateContext();

  const url = "/collections";

  useEffect(() => {
    dispatch({ type: START_LOADER, payload: url });
    authorizedFetcher(url).then((collections) =>
      dispatch(
        { type: SET_COLLECTIONS, payload: collections },
        { type: STOP_LOADER, payload: url }
      )
    );
    // eslint-disable-next-line
  }, []);

  return { collections, isLoading: loaders.includes(url) };
};
