import styled from "styled-components";
import { Card } from "../../../components/Basic";
import { ChartSubTitle, ChartTitle } from "../../../components/ChartTitle";
import { useCssProps } from "../../../utils/useCssProps";

const Wrapper = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 32px;
  position: relative;
`;

const ChartContainer = styled.div`
  height: 90%;
  width: 100%;
  ${useCssProps}
`;

export const ChartWrapper = ({
  children,
  title,
  subtitle,
  chartSpan,
  chartMargin,
  ...props
}) => {
  return (
    <Wrapper chartSpan={chartSpan} {...props}>
      <ChartTitle>
        {title}
        <ChartSubTitle>{subtitle}</ChartSubTitle>
      </ChartTitle>
      <ChartContainer margin={chartMargin || "0"}>{children}</ChartContainer>
    </Wrapper>
  );
};
