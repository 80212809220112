const captialToDashLowercase = (letter) =>
  letter.toUpperCase() === letter && letter !== "-"
    ? `-${letter.toLowerCase()}`
    : letter;

export const camelKebab = (string) =>
  string
    .split("")
    .map(captialToDashLowercase)
    .map((x) => x.toLowerCase())
    .join("");
