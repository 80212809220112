import styled from "styled-components";
import { useCssProps } from "../utils/useCssProps";
import { Box } from "./Basic";

export const ChartTitle = styled(Box)`
  font-weight: bold;
  font-size: 22px;
  color: var(--colors-grey-dark);
  position: relative;
  margin-bottom: 16px;
  ${useCssProps};
`;

export const ChartSubTitle = styled(Box)`
  font-size: 32px;
  color: var(--colors-grey-dark);
  position: absolute;
  font-weight: 200;
  z-index: 10;
  ${useCssProps};
`;
