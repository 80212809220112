export const START_LOADER = "START_LOADER";
export const STOP_LOADER = "STOP_LOADER";

export const SET_COLLECTIONS = "SET_COLLECTIONS";
export const SET_COLLECTION = "SET_COLLECTION";
export const SET_AGGREGATED_PERSONS = "SET_AGGREGATED_PERSONS";
export const SET_CATEGORIES = "SET_CATEGORIES";

export const OPEN_FILTER_MENU = "OPEN_FILTER_MENU";
export const OPEN_DATE_MENU = "OPEN_DATE_MENU";
export const CLOSE_MENU = "CLOSE_MENU";

export const CHANGE_FILTER = "CHANGE_FILTER";
export const SET_AGES = "SET_AGES";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";

export const SET_RANK = "SET_RANK";
