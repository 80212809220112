import styled from "styled-components";
import { cssObjectToString, keepCssProps } from "../../utils/cssObjectToString";

export const Button = styled.button`
  border: none;
  box-sizing: border-box;
  position: relative;
  transition: background 400ms;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  padding: 16px;
  ${(props) => cssObjectToString(keepCssProps(props))}
`;
