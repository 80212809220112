import { cssProperties } from "./cssProperties";
import { takeIf } from "./objects/take";
import { camelKebab } from "./strings/camelKebab";

const CSS_PROPERTIES = cssProperties();
const isCssProperty = (x) => CSS_PROPERTIES.includes(x);

export const keepCssProps = takeIf(
  (_, key) => isCssProperty(camelKebab(key)) || isCssProperty(key)
);

const cssPropStringReducer = (cssObject) => (propString, prop) =>
  `${propString}${camelKebab(prop)}: ${cssObject[prop].toString()};`;

/**
 * { backgroundColor: 'blue', color: 'green', notACssProp: 'foo' }
 * => "background-color: 'blue'; color: 'green';"
 */
export const cssObjectToString = (cssObject) =>
  Object.keys(cssObject).reduce(cssPropStringReducer(cssObject), "");
