import { useEffect, useState } from "react";

import { useAuthorizedFetcher } from "./useAuthorizedFetcher";

export const useFace = (id) => {
  const authorizedFetcher = useAuthorizedFetcher();
  const [face, setFace] = useState(null);

  useEffect(() => {
    if (id !== undefined) {
      authorizedFetcher(`/faces/${id}`).then(setFace);
    }
    // eslint-disable-next-line
  }, [id]);

  return face;
};
