import {
  SET_COLLECTION,
  SET_COLLECTIONS,
  CHANGE_FILTER,
  OPEN_DATE_MENU,
  OPEN_FILTER_MENU,
  CLOSE_MENU,
  SET_AGES,
  SET_START_DATE,
  SET_END_DATE,
  SET_AGGREGATED_PERSONS,
  SET_CATEGORIES,
  SET_RANK,
  START_LOADER,
  STOP_LOADER,
} from "./actionTypes";
import { toggleArrayValue } from "../utils/arrays/toggleArrayValue";

const actionSwitch = (loggingLevel) => (state, action) => {
  // NOTE: Temporary for dev purposes
  // do this better later on
  if (["DEBUG"].includes(loggingLevel)) {
    console.log(state);
    console.log(action);
  }

  switch (action.type) {
    case SET_COLLECTIONS: {
      return {
        ...state,
        loading: false,
        collections: action.payload,
      };
    }
    case SET_COLLECTION: {
      return {
        ...state,
        loading: false,
        collection: action.payload,
      };
    }
    case SET_AGGREGATED_PERSONS: {
      return {
        ...state,
        aggregatedPersons: action.payload,
      };
    }

    case SET_CATEGORIES: {
      return {
        ...state,
        filters: {
          ...state.filters,
          options: {
            ...state.filters.options,
            ...action.payload,
          },
          selected: {
            ...state.filters.selected,
            ...action.payload,
          },
        },
      };
    }

    case OPEN_DATE_MENU: {
      return {
        ...state,
        isFilterMenuOpen: false,
        isDateMenuOpen: true,
      };
    }
    case OPEN_FILTER_MENU: {
      return {
        ...state,
        isFilterMenuOpen: true,
        isDateMenuOpen: false,
      };
    }

    case CLOSE_MENU: {
      return {
        ...state,
        isFilterMenuOpen: false,
        isDateMenuOpen: false,
      };
    }

    case SET_AGES: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            ...state.filters.selected,
            age: { from: action.payload[0], to: action.payload[1] },
          },
        },
      };
    }

    case SET_START_DATE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            ...state.filters.selected,
            date: {
              ...state.filters.selected.date,
              from: action.payload,
            },
          },
        },
      };
    }

    case SET_END_DATE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            ...state.filters.selected,
            date: {
              ...state.filters.selected.date,
              to: action.payload,
            },
          },
        },
      };
    }

    case CHANGE_FILTER: {
      const nextSelected = toggleArrayValue(
        state.filters.selected[action.payload.category],
        action.payload.value
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            ...state.filters.selected,
            [action.payload.category]:
              nextSelected.length < 1
                ? state.filters.selected[action.payload.category]
                : nextSelected,
          },
        },
      };
    }

    case SET_RANK: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            ...state.filters.selected,
            rank: action.payload,
          },
        },
      };
    }

    case START_LOADER: {
      return {
        ...state,
        loaders: [...state.loaders, action.payload],
      };
    }

    case STOP_LOADER: {
      return {
        ...state,
        loaders: state.loaders.filter((x) => x !== action.payload),
      };
    }

    default: {
      return state;
    }
  }
};

const LOGGING_LEVEL = "NONE"; // NOTE: probably from config later on

export const reducer = (state, actions) => {
  const nextState = actions.reduce(actionSwitch(LOGGING_LEVEL), state);

  if (["DEBUG"].includes(LOGGING_LEVEL)) console.log(nextState);

  return nextState;
};
