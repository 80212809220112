const isArray = (x) => Array.isArray(x);
const isObject = (x) => typeof x === "object" && !isArray(x);

const shouldBeObject = (x) => {
  if (!isObject(x)) {
    throw new TypeError(`Input must be of type object, got: ${typeof x}`);
  }
};

const reduceObject = (condition) => (object) =>
  Object.keys(object).reduce((next, key) => {
    if (condition(next, key)) {
      return { ...next, [key]: object[key] };
    }
    return next;
  }, {});

// take('foo', 'baz')({foo: 1, bar: 2, baz: 3})
// => {foo: 1, baz: 3}
export const take =
  (...keys) =>
  (object) => {
    shouldBeObject(object);
    return reduceObject((_, key) => keys.includes(key))(object);
  };

export const takeIf = (filter) => (object) => {
  shouldBeObject(object);
  return reduceObject(filter)(object);
};
