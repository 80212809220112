import styled from "styled-components";
import { Grid } from "../../components/Basic";

export const DashboardGrid = styled(Grid)`
  flex: 1;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  grid-template-areas:
    "A B"
    "C C";
  height: 80vh;
`;
