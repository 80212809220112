const STYLE = document.body.style;

export const cssProperties = () => {
  const properties = Object.getOwnPropertyNames(
    STYLE.hasOwnProperty("background") ? STYLE : STYLE.__proto__
  )
    .filter((x) => STYLE[x] === "") // drop functions etc
    .map((prop) => {
      // de-camelCase
      prop = prop.replace(/[A-Z]/g, function ($0) {
        return "-" + $0.toLowerCase();
      });

      if (prop.startsWith("webkit-")) {
        prop = "-" + prop;
      }

      return prop;
    });

  return [...new Set(properties)];
};
