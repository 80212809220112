import { chain } from "@codewell/chain";
import { map } from "../../../../utils/arrays/map";
import { pick } from "../../../../utils/objects/pick";

export const prepareData = (data) =>
  chain(
    data,
    pick("age"),
    map(({ from, to, proportion }) => ({
      name: `${from.toFixed(0)}-${to.toFixed(0)}`,
      n: Math.round(proportion * 100),
    }))
  );
