import { useReducer, createContext, useContext } from "react";
import { initialState } from "./initialState.js";
import { reducer } from "./reducer.js";

const StateContext = createContext();

export const useStateContext = () => useContext(StateContext);

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const customDispatch = (...actions) => dispatch(actions);

  return (
    <StateContext.Provider value={{ state, dispatch: customDispatch }}>
      {children}
    </StateContext.Provider>
  );
};
