import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "./Button";

const Img = styled.img`
  width: 18px;
  color: var(--colors-grey-dark);
`;

export const IconButton = ({ icon, ...props }) => {
  const buttonRef = useRef(null);
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (buttonRef.current !== null) {
      if (width === null) {
        // Correct for "tall" look
        setWidth(buttonRef?.current?.offsetHeight + 4);
      }
    }
  }, [buttonRef, width]);

  return (
    <Button
      ref={buttonRef}
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor=" #ffffff"
      width={`${width}px`}
      {...props}
    >
      <Img alt="" src={icon} />
    </Button>
  );
};
