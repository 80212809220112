import { Flex } from "../Basic";
import { Map } from "../Map";
import { Tag } from "../Tag";
import { CHANGE_FILTER } from "../../state/actionTypes.js";
import { useStateContext } from "../../state/StateProvider";
import { identity } from "@nextml/lodestar";

export const DataFilter = ({ category, renderAs }) => {
  const { state, dispatch } = useStateContext();

  return (
    <Flex flexWrap="wrap">
      <Map
        data={state?.filters?.options[category]}
        renderAs={renderAs || identity}
        Component={Tag}
        keyPrefix={category}
        isSelected={(x) => state.filters.selected[category].includes(x)}
        onClick={(_, value) => {
          dispatch({
            type: CHANGE_FILTER,
            payload: { category, value },
          });
        }}
      />
    </Flex>
  );
};
