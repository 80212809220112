export const exclude =
  (...keys) =>
  (x) =>
    Object.keys(x).reduce((acc, key) => {
      if (!keys.includes(key)) {
        return { ...acc, [key]: x[key] };
      }

      return acc;
    }, {});
