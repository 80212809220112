import { faFaceSadTear } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Text } from "../components/Basic.jsx";

export const NoCollection = () => {
  return (
    <Row
      backgroundColor="#fff"
      borderRadius="4px"
      padding="32px"
      marginBottom="32px"
    >
      <FontAwesomeIcon
        icon={faFaceSadTear}
        color="var(--colors-grey-80)"
        fontSize="32px"
      />{" "}
      <Text
        marginLeft="16px"
        fontSize="14px"
        fontWeight="700"
        color="var(--colors-grey-70)"
      >
        No collection available...
      </Text>
    </Row>
  );
};
