import { useKeycloak } from "@react-keycloak/web";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import { useConfig } from "../state/ConfigProvider";
import { Layout } from "../components/Layout";

const Authentication = ({ children, loading, notAuthenticated }) => {
  const { keycloak } = useKeycloak();

  if (keycloak === undefined) {
    return loading;
  }

  if (!keycloak.authenticated) {
    return notAuthenticated;
  }

  return children;
};

export const AuthenticationProvider = ({ children }) => {
  const config = useConfig();

  const authClient = config?.auth ? new Keycloak(config.auth) : null;

  if (!authClient) {
    return null;
  }

  return (
    <ReactKeycloakProvider
      authClient={authClient}
      initOptions={{
        flow: "implicit",
        onLoad: "login-required",
        // TODO: Investigate check-sso
        // onLoad: "check-sso",
        // silentCheckSsoRedirectUri:
        //   window.location.origin + "/silent-check-sso.html",
      }}
    >
      <Authentication
        loading={<Layout loadingMessage="Authenticating..." isLoading />}
        notAuthenticated={
          <Layout loadingMessage="Authenticating..." isLoading />
        }
      >
        {children}
      </Authentication>
    </ReactKeycloakProvider>
  );
};
