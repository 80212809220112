import styled from "styled-components";
import { Card, Box, Row } from "../../../../components/Basic";
import { ChartTitle } from "../../../../components/ChartTitle";
import { Face } from "./Face";

const Wrapper = styled(Card)`
  padding: 32px 32px;
`;

const Divider = styled(Box)`
  width: 100%;
  background-color: var(--colors-grey-10);
  height: 2px;
  margin: 32px 0;
`;

const compareFaces = (faceA, faceB) => (faceA.weight < faceB.weight ? 1 : -1);

export const FaceRepresentation = ({ data, ...props }) => (
  <Wrapper {...props}>
    <ChartTitle textAlign="center">Highest Representation</ChartTitle>
    <Row justifyContent="center">
      {data.most_representative_faces.sort(compareFaces).map((face) => (
        <Face
          key={face.id}
          id={face.id}
          weight={face.weight}
          width="25%"
          minWidth="100px"
          padding=".5vw"
        />
      ))}
    </Row>

    <Divider />

    <ChartTitle textAlign="center">Lowest Representation</ChartTitle>
    <Row justifyContent="center">
      {data.least_representative_faces.sort(compareFaces).map((face) => (
        <Face
          key={face.id}
          id={face.id}
          weight={face.weight}
          width="25%"
          minWidth="100px"
          padding=".5vw"
        />
      ))}
    </Row>
  </Wrapper>
);
